import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { EmojiResource } from '@atlaskit/emoji/resource';
import { ResourcedEmoji } from '@atlaskit/emoji/element';
import { N20A } from '@atlaskit/theme/colors';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';

import { getEmojiProvider } from '@confluence/fabric-providers';

// This file is based off of the
// next/packages/emoji-title/src/EmojiComponent.tsx

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmojiSpan = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		overflow: 'hidden',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.emoji-common-emoji-sprite': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		height: '20px !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		width: '20px !important',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonWrapper = styled.div<any>({
	maxWidth: '26px',
	display: 'inline-block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ size }) => size,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ size }) => size,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmojiSkeleton = styled.div<any>({
	background: token('color.skeleton', N20A),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontSize: ({ size }) => size,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ size }) => size,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ size }) => size,
});

const EMOJI_HEIGHT_MINI = 20;
export const EmojiPlaceholder = (props) => (
	<SkeletonWrapper size={`${props.height || 20}px`} data-testid="emoji-placeholder">
		<EmojiSkeleton size={`${props.height || 20}px`} />
	</SkeletonWrapper>
);

export interface EmojiComponentProps {
	id?: string;
	shortName: string;
	cloudId: string;
	userId: string | null;
}

/**
 *
 * @param param
 *
 * In order to load media emojis we need a few parameters
 * Because Emojis within ADF provides: shortName, Id, text
 * (e.g)
 *     "shortName": ":slight_smile:",
 *     "id": "1f642",
 *     "text": "🙂"
 */
const FetchedEmojiComponent = ({
	id,
	shortName,
	cloudId,
	userId,
}: EmojiComponentProps & WithAnalyticsEventsProps) => {
	return (
		<EmojiSpan data-testid="toc-emoji-wrapper">
			<ResourcedEmoji
				emojiId={{ id, shortName }}
				emojiProvider={getEmojiProvider(EmojiResource, {
					cloudId,
					userId,
				})}
				fitToHeight={EMOJI_HEIGHT_MINI}
				showTooltip={false}
			/>
		</EmojiSpan>
	);
};

export const EmojiComponent = withAnalyticsEvents()(FetchedEmojiComponent);
